'use client';
import { atom } from 'jotai';
import { ReactNode } from 'react';

import { ModalProps } from '../../Entities/Modal/Modal.entity';
import { sendNativeMessage } from '@mobile-app/Utils/SendNativeMessage';

const modalInitialData = {
  header: null,
  content: null,
  modalAction: null,
  children: null,
  centered: true,
  onClose: () => null,
  onOpen: () => null,
};
export const modalDataAtom = atom<ModalProps>(modalInitialData);

export const isModalOpenAtom = atom(false);

export const closeModalAtom = atom(null, (get, set, shouldReset?: boolean) => {
  const modalData = get(modalDataAtom);
  modalData.onClose?.();

  set(isModalOpenAtom, false);
  const pathname = window.location.pathname;

  if (!pathname.includes('/checkout/')) sendNativeMessage({ showBackButton: true });

  if (shouldReset) set(modalDataAtom, modalInitialData);
});

export const resetModalAtom = atom(null, (get, set) => {
  set(modalDataAtom, modalInitialData);
  set(isModalOpenAtom, false);
});

export const openModalAtom = atom(null, (_get, set, content: ReactNode, modalProps: ModalProps) => {
  set(modalDataAtom, { onClose: () => null, content, ...modalProps });
  set(isModalOpenAtom, true);

  sendNativeMessage({ showBackButton: false });

  modalProps.onOpen?.();
});
