import NextNavigation from 'next-source/navigation';

import { useRouter } from './NativeUseRouter';

export * from 'next-source/navigation';

export { useRouter } from './NativeUseRouter';

const Navigation = { ...NextNavigation, useRouter };

export default Navigation;
