'use client';

import React from 'react';

import { sanitizePath, createNativeMessage } from '@mobile-app/Constants/NativeScreenTypeMap';
import { sendNativeMessage } from '@mobile-app/Utils/SendNativeMessage';

import Cookies from 'js-cookie';
import Link, { LinkProps } from 'next-source/link';

type Props = {
  href: string;
  legacyBehavior?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: string;
} & LinkProps & { children: React.ReactNode };

const NativeLinkWrapper = React.forwardRef<HTMLAnchorElement, Props>(({ ...props }, ref) => {
  const href = props.href as string;
  const activeServiceConsumer = Cookies.get('activeServiceConsumer');
  const isNative = activeServiceConsumer === 'native';
  const sanitizedHref = sanitizePath(href);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    console.log(`Navigation [LINK] to ${href} intercepted and pushed natively`);

    sendNativeMessage(createNativeMessage(sanitizedHref));
  };

  if (isNative && !sanitizedHref.startsWith('#')) {
    const newProps = { ...props, href: sanitizedHref };
    if (!props.legacyBehavior) {
      return <Link {...newProps} ref={ref} onClick={handleClick} prefetch={true} />;
    }

    return (
      <Link {...newProps} ref={ref} prefetch={true}>
        {React.cloneElement(newProps.children as React.ReactElement, {
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (newProps.onClick) newProps.onClick(e);
            handleClick(e);
          },
        })}
      </Link>
    );
  }

  return <Link {...props} ref={ref} />;
});

NativeLinkWrapper.displayName = 'NativeLinkWrapper';

export default NativeLinkWrapper;
