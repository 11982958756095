'use client';
import { useMemo } from 'react';

import {
  getScreenTitleFromPath,
  getScreenTypeFromPath,
  sanitizePath,
} from '@mobile-app/Constants/NativeScreenTypeMap';
import { sendNativeMessage } from '@mobile-app/Utils/SendNativeMessage';
import Cookies from 'js-cookie';
import { useRouter as useNextRouter } from 'next-source/navigation';

export const useRouter = () => {
  const activeServiceConsumer = Cookies.get('activeServiceConsumer');
  const isNative = activeServiceConsumer === 'native';

  const router = useNextRouter();

  const customPush = (path: string) => {
    console.log(`Navigation [PUSH] to ${path} intercepted and pushed natively`);
    sendNativeMessage({
      navigate: {
        url: sanitizePath(path),
        screenType: getScreenTypeFromPath(path),
      },
      updateHeader: getScreenTitleFromPath(path),
    });
  };

  return useMemo(() => {
    if (!isNative) return router;

    return { ...router, push: customPush };
  }, []);
};
